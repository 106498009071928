<template>
  <nav class="navbar">
    <div class="logo">
      <router-link to="/">
        <img src="@/assets/images/LogoWhite2.png" alt="Logo les ateliers cgm7">
      </router-link>
    </div>
    <button @click="toggleMenu" class="hamburger">&#9776;</button>
    <div class="menu" :class="{ 'active': menuOpen }">
      <div class="menu-item">
        <router-link to="/">Accueil</router-link>
      </div>
      <div class="menu-item">
        <router-link to="/Services">Services</router-link>
      </div>
      <div class="menu-item">
        <router-link to="/partenaires">Partenaires</router-link>
      </div>
      <div class="menu-item">
        <router-link to="/à-propos">À propos</router-link>
      </div>
    </div>
    <button @click="showModal = true" class="contact-button">Contact</button>
  </nav>
  <div v-if="showModal" class="modal-overlay" @click="closeModalOnOverlayClick">
    <div class="modal" @click.stop>
      <button class="modal-close" @click="showModal = false">X</button>
      <form name="contact" method="POST" @submit.prevent="handleSubmit">
        <input type="hidden" name="form-name" value="contact">
        
        <div class="form-group">
          <label for="name">Nom / Prénom</label>
          <input id="name" v-model="name" name="name" placeholder="Jean Dupont" required>
        </div>
        
        <div class="form-group">
          <label for="phone">Numéro de téléphone</label>
          <input id="phone" v-model="phone" name="phone" type="tel" placeholder="01 23 45 67 89" required>
        </div>
        
        <div class="form-group">
          <label for="email">Email</label>
          <input id="email" v-model="email" name="email" type="email" placeholder="jean.dupont@example.com" required>
        </div>
        
        <div class="form-group">
          <label for="message">Votre message</label>
          <textarea id="message" v-model="message" name="message" placeholder="Écrivez votre message ici" required></textarea>
        </div>
        
        <button class="submit-button" type="submit">Envoyer</button>
      </form>
    </div>
  </div>
  <div v-if="showToast" :class="['toast', toastStatus ? 'toast-success' : 'toast-error']">{{ toastMessage }}</div>
</template>

<script>

export default {
  data() {
    return {
      menuOpen: false,
      showModal: false,
      name: '',
      phone: '',
      email: '',
      message: '',
      showToast: false,
      toastMessage: '',
      toastStatus: true 
    };
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    handleSubmit() {
      console.log("handleSubmit a été appelée");

      let formData = new FormData();
      formData.append('form-name', 'contact');
      formData.append('name', this.name);
      formData.append('phone', this.phone);
      formData.append('email', this.email);
      formData.append('message', this.message);

      fetch("/", {
        method: "POST",
        body: formData
      })
      .then((response) => {
        if (response.ok) {
          
          this.toastStatus = true;
          this.toastMessage = "Merci pour votre message !";
          this.showToast = true;
          setTimeout(() => this.showToast = false, 3000);
          this.name = '';
          this.phone = '';
          this.email = '';
          this.message = '';
        } else {
          
          throw new Error("Statut de la réponse non attendu");
        }
      })
      .catch((error) => {
       
        console.error(error);
        this.toastStatus = false;
        this.toastMessage = "Erreur lors de l'envoi du message. Veuillez réessayer.";
        this.showToast = true;
        setTimeout(() => this.showToast = false, 3000);
      });
    },
    closeModalOnOverlayClick(event) {
      if(event.target.classList.contains('modal-overlay')) {
        this.showModal = false;
      }
    }
  }
}
</script>


 
<style scoped>
@font-face {
    font-family: 'Avenir Next LT Pro';
    src: url('@/assets/fonts/AvenirNextLTPro-Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next LT Pro';
    src: url('@/assets/fonts/AvenirNextLTPro-It.otf') format('opentype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir Next LT Pro';
    src: url('@/assets/fonts/AvenirNextLTPro-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}
.navbar {
    position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #333;  
  z-index: 1000;  
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 15px;
    background-color: #ffffff;
    box-shadow: 0px 2px 10px #00ADB5;
    height: 90px;

}
.menu-item {
  margin-bottom: 10px;
  display: inline-block;
  margin-right: 28px;
}

.menu-item:last-child {
  margin-right: 0;
}

.menu-item a {
  color: #000000;
  text-decoration: none;
  transition: color 0.3s;
  font-size: 18px;
  font-weight: bold;
}

.menu-item a:hover {
  color: #edc80c;
}
  
  .logo img {
    height: 90px;
    width: auto; 
  }
.contact-button {
  font-family: 'Avenir Next LT Pro';
  background-color: #BBDCDB; 
    color: white;
    padding: 10px 20px;
    margin-right: 50px;  
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: bold;
    margin-top: 10px;
}

.contact-button:hover {
  background-color: #358381; 
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.modal {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 80%;
  max-width: 500px;
  position: relative;
}
.main-content {
  padding-top: 60px; 
}
.menu {
font-family: 'Avenir Next LT Pro';
z-index: 1010; 
margin-left: 675px;
margin-top: 74.9px;
}
.hamburger {
    display: none;
    background-color: transparent;
    border: none;
    font-size: 1.5rem;
    color: #000;
}

.menu {
    display: flex;
    z-index: 1010;
}

@media (max-width: 768px) {

  
    .hamburger {
        display: block;
    }
 

    .menu {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        background-color: #ffffff;
        width: 100%;
        box-shadow: 0px 2px 10px #00ADB5;
        transform: translateY(0%);
        transition: transform 0.3s;
    }
    .menu.active { 
        display: block;
    }
    .menu-item {
      margin-top: 20px;  
    margin-bottom: 10px;
    display: inline-block;
    margin-right: 30px;
      
    }

    .menu-item a {
        display: block;
        padding: 10px 0;
    
    }
  }
    .menu:not(.active) {
        transform: translateY(-100%);
    
}
.menu:not(.active) {
  transform: translateY(-100%);
}
.toast {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #358381;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 3000;
}
.toast-success {
        background-color: #358381;
    }

    .toast-error {
        background-color: #f44336; 
    }

    .modal-close {
  position: absolute;
  right: 20px;
  top: 15px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #00ADB5;
}

.form-group {
  font-family: 'Avenir Next LT Pro';
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

label {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
}

input, textarea {
  font-family: 'Avenir Next LT Pro';
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  transition: border-color 0.3s;
}

input:focus, textarea:focus {
  border-color: #00ADB5;
  outline: none;
}

.submit-button {
  font-family: 'Avenir Next LT Pro';
  background-color: #00ADB5;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: bold;
}

.submit-button:hover {
  background-color: #358381;
}
  </style>
  