<template>
    <div>
        <!-- Hero Section with Image & Overlay -->
        <div class="hero-section" :class="{ animate: animateHero }" ref="heroSection">
            <img class="hero" :class="{ darken: showOverlay }" src="@/assets/images/BureauCgm7.jpg" alt="Photo des ateliers cgm7">
            <div class="text-overlay" :class="{ 'initial': !initiated, 'show': showOverlay }">
                <div class="text-content">
                    <h1>Les ateliers cgm7</h1>
                    <p>Bienvenue chez Les Ateliers CGM7, votre partenaire de confiance pour tous vos projets de construction, d'extension et de rénovation. Forts d'une expérience solide dans le domaine du bâtiment, nous mettons notre savoir-faire à votre service pour transformer vos idées en réalités durables et qualitative.</p>
                </div>
            </div>
            <div class="lamp-logo" @click="toggleOverlay">
                <img src="@/assets/images/Ampoule.svg" alt="Lampe Logo">
            </div>
            <div class="scroll-arrow-container">
                <div @click="scrollToNextSection" v-scroll-to="'#next-section'" class="scroll-arrow">&#8595;</div>
            </div>
        </div>


        <div id="next-section" class="next-section">
         
            <div class="img-container">
                <img class="section-image" src="@/assets/images/AvantProjet.jpeg" alt="Une autre image pour embellir">
            </div>
        
            <div class="text-container">
                <h2>Bienvenue aux ateliers CGM7 !</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris eu.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nisi orci, porta et magna id, vestibulum iaculis elit.</p>
            </div>
        </div>

        <div ref="statsBar" class="stats-bar">
    <div class="stat">
        <strong>Quelques chiffres...</strong>
    </div>
    <div class="stat">
        <CountUp v-if="animateStats" :endVal="5107" :duration="1.5" /> projets
    </div>
    <div class="stat">
        <CountUp v-if="animateStats" :endVal="31" :duration="3.5" /> ans d'expérience
    </div>
    <div class="stat">
        <CountUp v-if="animateStats" :endVal="43" :duration="4.5" /> clients pro
    </div>
</div>

    </div>
    
    <div class="new-section">
  
    <div class="new-text-container">
        <h2>Titre de la nouvelle section</h2>
        <p>Texte explicatif pour cette section. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nisi orci, porta et magna id, vestibulum iaculis elit.</p>
    </div>
    
    <div class="new-img-container">
        <img class="new-section-image" src="@/assets/images/NouvelleImage.jpg" alt="Description de l'image">
    </div>
</div>

</template>

<script>
import CountUp from '@/components/CountUp.vue';

export default {
    components: {
        CountUp
    },
    name: "HomePage",
    data() {
        return {
            showOverlay: false, 
            initiated: false,
            animateStats: false,
            animateHero: false
        };
    },
    watch: {
        animateHero(newValue) {
            if (newValue) {
                // Logique quand le hero est en vue
            } else {
                // Logique quand le hero n'est plus en vue
            }
        }
    },
    mounted() {
        setTimeout(() => {
            this.initiated = true; 
            this.showOverlay = true;
            this.checkElementInView(this.$refs.heroSection, 'animateHero');
        }, 1); 

        window.addEventListener('scroll', this.checkIfStatsInView);
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.checkIfStatsInView);
    },
    methods: {
        scrollToNextSection() {
            this.$nextTick(() => {
                const topPosition = this.getOffsetTop(this.$refs.nextSection) - 100; 
                window.scrollTo({
                    top: topPosition,
                    behavior: 'smooth'
                });
            });
        },
        getOffsetTop(element) {
            let offsetTop = 0;
            while (element) {
                offsetTop += element.offsetTop;
                element = element.offsetParent;
            }
            return offsetTop;
        },
        toggleOverlay() {
            this.showOverlay = !this.showOverlay;
        },
        checkIfStatsInView() {
    this.checkElementInView(this.$refs.heroSection, 'animateHero');
    this.checkElementInView(this.$refs.statsBar, 'animateStats');
},

checkElementInView(element, dataProp) {
    if (element) {
        const rect = element.getBoundingClientRect();
        if (rect.top <= window.innerHeight && rect.bottom >= 0) {
            if (!this[dataProp]) {
                this[dataProp] = true;
            }
        } else {
            if (this[dataProp]) {
                this[dataProp] = false;
            }
            if (dataProp === 'animateHero') {
                this.initiated = false; // Réinitialiser l'animation
                setTimeout(() => {
                    this.initiated = true;
                }, 10); // Petit délai pour permettre la réinitialisation
            }
        }
    }
}


}
}
</script>


<style scoped>
@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-15px);
    }
    60% {
        transform: translateY(-7px);
    }
}

.hero {
    display: block;
    width: 100%;
    height: 100vh; 
    object-fit: cover; 
    margin-top: 0;
    transition: filter 0.5s ease; 
}

.hero-section {
    position: relative;
    height: 100vh; 
    display: flex;
    align-items: center; 
    justify-content: center; 
    overflow: hidden;
}
.scroll-arrow-container {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
}


.scroll-arrow {
    font-size: 3rem;
    color: white;
    cursor: pointer;
    transition: opacity 0.3s;
    animation: bounce 3.5s infinite 0s; 
}

.scroll-arrow:hover {
    opacity: 0.7;
}

.next-section {
    font-family: 'Avenir Next LT Pro';
    padding: 105px 0;
    background-color: #f5f5f5;
    text-align: center;
}

.next-section img {
    max-width: 100%;
    height: auto;
    margin: 20px 0;
}

@media (max-width: 768px) {
    .hero {
        height: 50vh; 
    }
    .hero-section {
        height: 50vh;
    }
}
.text-overlay {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 33.33%;
    background: transparent;
    opacity: 0;
    transform: translateX(100%);
    transition: 
    opacity 1s ease, 
    transform 1s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.text-overlay::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(57, 61, 61, 0), #393d3d); 
    pointer-events: none; 
}
.text-overlay .text-content {
    font-family: 'Avenir Next LT Pro';

    color: white;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    position: relative;  
}

.text-overlay.show {
    opacity: 1;
    transform: translateX(0);
}
.text-overlay h1 {
    font-size: 40px;
    margin-bottom: 10px;
}

.text-overlay p {
    font-size: 24px;
}

.lamp-logo {
    position: absolute;
    bottom: 20px;
    left: 20px;
   /* Ajout d'un z-index élevé */
}

.lamp-logo img {
    height: 50px; /* à ajuster selon la taille de votre logo */
    cursor: pointer;
    filter: invert(1);
   
}

.hero-section:not(.animate) .text-overlay {
    opacity: 0;
    transform: translateX(100%);
}

.text-overlay.initial {
    opacity: 0;
    transform: translateX(100%);
    transition: none; /* Pour éviter que l'animation ne démarre immédiatement */
}
.stats-bar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #BBDCDB; /* Remplacez par votre code couleur bleu exact */
    color: white;
    padding: 60px 0;
    font-weight: bold;
    font-family: 'Avenir Next LT Pro';
    font-size: 30px;
}

.stat {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.next-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px 0;
}

.text-container {
    flex: 0 0 50%; /* Cela occupe la moitié de la largeur */
    padding: 0 40px;
    font-size: 25px;
}

.img-container {
    flex: 0 0 45%; /* Cela occupe un peu moins de la moitié de la largeur pour équilibrer avec le padding */
}

.section-image {
    max-width: 100%;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2); /* Ombre pour l'image */
}
.new-section {
    font-family: 'Avenir Next LT Pro';
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px 0;
    margin-top: 50px; /* espace par rapport à la section précédente */
}

.new-text-container {
    flex: 0 0 50%;
    padding: 0 40px;
    font-size: 25px;
}

.new-img-container {
    flex: 0 0 45%;
}

.new-section-image {
    max-width: 100%;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15); /* Ombre pour l'image */
}
.hero-section:not(.animate) {
    opacity: 0.5;
}

.hero.darken {
    filter: brightness(0.7); /* cela réduit la luminosité de l'image de moitié */
}

</style>


