import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../components/HomePage.vue';
import AboutMe from '../components/AboutMe.vue';
import AllParteners from '../components/AllParteners.vue';
import ServicesTypes from '../components/ServicesTypes.vue';

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/à-propos',
    name: 'AboutMe',
    component: AboutMe
  },
  {
    path: '/partenaires',
    name: 'AllParteners',
    component: AllParteners
  },
  {
    path: '/Services',
    name: 'ServicesTypes',
    component: ServicesTypes
  }
  // ... autres routes
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
