<template>
  <div class="modal" v-if="currentService" @click="closeModal">
    <div class="modal-content" @click.stop>
      <span @click="closeModal()" class="close">&times;</span>
      <h2>{{ currentService.title }}</h2>
<p>{{ currentService.detailedDescription }}</p>
<h3>{{ currentService.additionalTitle1 }}</h3>
<p>{{ currentService.additionalText1 }}</p>
<h3>{{ currentService.additionalTitle2 }}</h3>
<p>{{ currentService.additionalText2 }}</p>
  </div>
</div>
    <div class="services-types-container">
      <h1>Nos Services</h1>

      <div class="services-grid">
        <div class="service-card" v-for="service in primaryServices" :key="service.title">
          <img :src="service.image" alt="" />
          <h2>{{ service.title }}</h2>
          <p>{{ service.description }}</p>
          <button class="details-button" @click="openModal(service)">En savoir plus</button>
        </div>
      </div>

      <div class="services-grid second-grid">
        <div class="service-card" v-for="service in secondaryServices" :key="service.title">
          <img :src="service.image" alt="" />
          <h2>{{ service.title }}</h2>
          <p>{{ service.description }}</p>
          <button class="details-button" @click="openModal(service)">En savoir plus</button>
        </div>
      </div>

      <div class="services-grid third-grid">
        <div class="service-card" v-for="service in thirdServices" :key="service.title">
          <img :src="service.image" alt="" />
          <h2>{{ service.title }}</h2>
          <p>{{ service.description }}</p>  
          <button class="details-button" @click="openModal(service)">En savoir plus</button>
      </div>
         </div>
    </div>
</template>

<script>
  import AvantProjetPermisImage from '@/assets/images/AvantProjetPermis.jpeg';
  import RenovationExtensionImage from '@/assets/images/RenovationExtension.jpeg';
  import MetreQuantiExeImage from '@/assets/images/MetreQuantiExe.jpeg'; // Remplacez par le chemin correct
  import SupportComImage from '@/assets/images/SupportCom.jpeg'; // Remplacez par le chemin correct
  import AccompagnementMaitreOuvrageImage from '@/assets/images/AccompagnementMaitreOuvrage.jpeg';


  export default {
    data() {
      return {
        primaryServices: [
          {
            image: AvantProjetPermisImage,
            title: 'Avant projet et permis de construire',
            description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sit, explicabo.',
            additionalTitle1: "Titre supplémentaire 1 pour le service 1",
            additionalText1: "Texte supplémentaire 1 pour le service 1",
            additionalTitle2: "Titre supplémentaire 2 pour le service 1",
            additionalText2: "Texte supplémentaire 2 pour le service 1",
          },
          {
            image: RenovationExtensionImage,
            title: 'Rénovation et extension',
            description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sit, explicabo.',
            additionalTitle1: "Titre supplémentaire 1 pour le service 1",
            additionalText1: "Texte supplémentaire 1 pour le service 1",
            additionalTitle2: "Titre supplémentaire 2 pour le service 1",
            additionalText2: "Texte supplémentaire 2 pour le service 1",
          },

        ],
        secondaryServices: [ 
          {
            image: MetreQuantiExeImage,
            title: "Métré quantitatif et dossier d'exécution", //
            description: 'Description du nouveau service 1.', 
            additionalTitle1: "Titre supplémentaire 1 pour le service 1",
            additionalText1: "Texte supplémentaire 1 pour le service 1",
            additionalTitle2: "Titre supplémentaire 2 pour le service 1",
            additionalText2: "Texte supplémentaire 2 pour le service 1",
          },
          {
            image: SupportComImage,
            title: 'Support commerciaux', 
            description: 'Description du nouveau service 2.',
            additionalTitle1: "Titre supplémentaire 1 pour le service 1",
            additionalText1: " Mise en forme de plaquette de présentations pour communiquer et aider à la vente. Ce peut être un document ponctuel et ciblé. Un diaporama de presentation de société ou commercialisation d'un programme. Mise en valeur des prestations. Les ateliers cgm7 ont la maîtrise des applications informatique, la connaissance des produits et les images du secteur de la maison individuelle, qui permettent d'établir des supports d'aide à la vente.",
            additionalTitle2: "Titre supplémentaire 2 pour le service 1",
            additionalText2: "Texte supplémentaire 2 pour le service 1",
          }
        ],
        thirdServices: [ 
          {
            image: AccompagnementMaitreOuvrageImage,
            title: "Accompagnement du maître d'ouvrage", 
            description: 'Description du nouveau service 1.',
            additionalTitle1: "Titre supplémentaire 1 pour le service 1",
            additionalText1: "Texte supplémentaire 1 pour le service 1",
            additionalTitle2: "Titre supplémentaire 2 pour le service 1",
            additionalText2: "Texte supplémentaire 2 pour le service 1",
          }
        ],
        currentService: null 
      };
    },
    methods: {
      openModal(service) {
        this.currentService = service;
      },
      closeModal() {
        this.currentService = null;
      }
    }
  }
</script>
  
<style scoped>
  .services-types-container {
    font-family: 'Avenir Next LT Pro';
    padding: 110px 15%;
    text-align: center;
  }
  
  h1 {
    font-size: 2.5em;
    margin-bottom: 30px;
    color: #333;
  }
  
  .services-grid {
    display: flex;
    flex-direction: column; 
    gap: 20px;
    align-items: center; 
  }

  @media (min-width: 768px) {
    .services-grid {
        flex-direction: row; 
        justify-content: center;
    }
  }
  
  .service-card {
    font-family: 'Avenir Next LT Pro';
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s;
    /* Ajoutez les deux lignes suivantes */
    width: 700px;  /* ou toute autre largeur appropriée */
    height: 400px;  /* ou toute autre hauteur appropriée */
}

  
  .service-card:hover {
    transform: translateY(-10px);
  }
  
  .service-card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .service-card h2 {
    font-size: 1.5em;
    margin-top: 20px;
  }
  
  .service-card p {
    padding: 10px 20px;
    font-size: 1em;
    color: #666;
    border-top: 1px solid #eee;
  }
  .second-grid {
    margin-top: 50px;
  }
.third-grid {
margin-top: 50px;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.modal-content {
  font-family: 'Avenir Next LT Pro';
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover, .close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.modal-content img {
    width: 100%;
    max-height: 200px;
    object-fit: cover;
}

.details-button {
  font-family: 'Avenir Next LT Pro';
  background-color: #BBDCDB; 
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 18px;
    font-weight: bold;
  
}
.details-button:hover {
  background-color: #358381; /* Couleur de votre choix pour le hover */
}


</style>
