import { createApp } from 'vue';
import App from './App.vue';
import './assets/css/style.css';
import { initFlowbite } from 'flowbite';
import router from './router';
import VueScrollTo from 'vue-scrollto'

const app = createApp(App);
app.use(router);
initFlowbite(app);
app.mount('#app');
app.use(VueScrollTo);