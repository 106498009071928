// CountUp.vue
<template>
    <div ref="counter">{{ currentVal }}</div>
</template>

<script>
import { CountUp } from 'countup.js';

export default {
    props: {
        endVal: {
            type: Number,
            required: true
        },
        duration: {
            type: Number,
            default: 2
        }
    },
    data() {
        return {
            countUpInstance: null,
            currentVal: 0
        }
    },
    mounted() {
        this.initializeCountUp();
    },
    methods: {
        initializeCountUp() {
            const options = {
                duration: this.duration
            };

            this.countUpInstance = new CountUp(this.$refs.counter, this.endVal, options);

            if (!this.countUpInstance.error) {
                this.startAnimation();
            } else {
                console.error(this.countUpInstance.error);
            }
        },
        startAnimation() {
            this.countUpInstance.reset();
            this.countUpInstance.start(() => {
                this.currentVal = this.endVal;
            });
        },
        resetAnimation() {
            this.currentVal = 0;
        }
    },
    watch: {
        animate(newVal) {
            if (newVal) {
                this.startAnimation();
            } else {
                this.resetAnimation();
            }
        }
    }
}
</script>
