<template>
  <div id="app" class="relative min-h-screen flex flex-col">
      <NavBar />
      <router-view />
      <AppFooter />
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue';
import AppFooter from './components/AppFooter.vue';  


export default {
  name: 'App',
  components: {
      NavBar,
      AppFooter
  }
};
</script>

<style>
body, #app {
  margin: 0;
  padding: 0;
}
</style>
