<template>
    <footer>
      <!-- Logo de l'entreprise -->
      <div>
          <img class="company-logo" src="@/assets/images/LogoGreen2.png" alt="Logo de l'entreprise">
      </div>
  
      <!-- Adresse, téléphone et email -->
      <div class="center">
          <a :href="googleLink" target="_blank">38540, Valencin</a>
          <a :href="phoneLink">06.87.48.51.17</a>
          <a :href="emailLink">carole.lesateliers@cgm7.fr</a>
      </div>
  
      <!-- Logos des réseaux sociaux -->
      <div>
          <a :href="instagramLink" target="_blank">
              <img src="@/assets/images/LogoInstagram.png" alt="Logo Instagram">
          </a>
          <a :href="linkedinLink" target="_blank">
            <img class="linkedin-logo" src="@/assets/images/LogoLinkedIn.png" alt="Logo LinkedIn">
          </a>
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    data() {
      return {
        googleLink: "https://www.google.com",
        phoneLink: "tel:0687485117",
        emailLink: "mailto:carole.lesateliers@cgm7.fr",
        instagramLink: "https://instagram.com/cgm.seven?igshid=MzRlODBiNWFlZA==",
        linkedinLink: "https://www.linkedin.com/in/carole-guihamat-891918284/"
      };
    }
  };
  </script>
  
  <style scoped>
  footer {
      background-color: #BBDCDB;
      display: flex;
      justify-content: space-between;
      color: white;
  }
  
  footer a {
      color: white;
      font-family: 'Avenir Next LT Pro';
      text-decoration: none;
      margin-bottom: 5px;
      display: block;
      padding-top: 20px;
      padding-right: 110px;
      font-size: 17px;
  }
  
  footer a:hover {
      text-decoration: underline;
      font-weight: bold;
  }
  
  footer .center {
  
      text-align: center;
  }
  
  footer img {
      height: 46px;

    
  }
  .company-logo {
    height: 140px;
    width: auto; 
    margin-left: 23px;
    
    
}
.linkedin-logo {
    filter: brightness(0) invert(1); /* Ceci rendra l'image complètement blanche */
}


@media (max-width: 768px) {
  footer {
    flex-direction: column; /* Organisez le contenu verticalement */
    align-items: center; /* Centrez le contenu horizontalement */
    padding: 10px; /* Ajoutez un peu d'espace tout autour */
  }

  footer .center {
    order: 1; /* Assurez-vous que l'adresse, le téléphone et l'e-mail apparaissent en premier */
    padding: 0 10px; /* Ajoutez un peu d'espace sur les côtés */
  }

  footer a {
    padding-right: 0; /* Retirez l'espacement à droite */
    text-align: center; /* Centrez le texte */
  }

  .company-logo {
    order: 2; /* Placez le logo de l'entreprise en deuxième position */
    margin-left: 0; /* Retirez la marge à gauche */
    margin-bottom: 10px; /* Ajoutez un peu d'espace en bas */
  }

  footer > div:last-child {
    order: 3; /* Assurez-vous que les logos des réseaux sociaux apparaissent en dernier */
    margin-bottom: 10px; /* Ajoutez un peu d'espace en bas */
  }
}
</style>
  
